import React, { useEffect } from "react"
import { css } from "@emotion/core"
import { connect } from "react-redux"
import { navigate } from "gatsby"

import { Layout, Title, SectionCenter, PrimaryButton } from "../components"
import { getCurrentPath } from "../redux/actions"
import terms from "../utils/terms"

const Terms = ({ location, getCurrentPath }) => {
  const onReturn = () => {
    navigate("/")
  }
  useEffect(() => {
    getCurrentPath(location.pathname)
  })
  return (
    <Layout>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <Title title="Terms & Conditions" fontColor="var(--clr-primary-300)" />
        <SectionCenter>
          <div
            css={css`
              width: 90%;
              margin: 0 auto;
              max-width: 800px;
            `}
          >
            {terms.split("\n").map((p, index) =>
              p[p.length - 1] !== "." && p.length < 76 ? (
                <p style={{ fontWeight: "bold" }} key={index}>
                  {p}
                </p>
              ) : (
                <p>{p}</p>
              )
            )}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <PrimaryButton
              textSize="0.875rem"
              textTransform="capitalize"
              maxWidth="none"
              margin="3rem 0 0 0"
              onClick={onReturn}
            >
              Return
            </PrimaryButton>
          </div>
        </SectionCenter>
      </section>
    </Layout>
  )
}

export default connect(null, { getCurrentPath })(Terms)
